<template>
  <div class="main index" v-if="!isLoading" ref="element">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Assessment Report</b></h1>
      </div>
      <div class="column bar">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Assessment</h3>
            </li>
            <li>
              <router-link :to="{ name: `reportAssessment` }">
                <h3 class="is-size-6">Assessment Report</h3>
              </router-link>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">
                {{ report.assessment.title }} - NIT: {{ meta.nit }}
              </h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-12">
        <div class="card card-table">
          <div>
            <div class="columns">
              <div class="column is-12">
                <b-tabs type="is-hcc" v-model="activeTab">
                  <!-- For proctoring image -->
                  <b-tab-item label="Proctoring">
                    <div class="columns is-multiline header-proctoring">
                      <div class="column is-10">
                        <label class="title">Summary</label>
                      </div>
                      <div class="column is-2">
                        <b-field grouped position="is-right">
                          <b-button
                            type="is-hcc"
                            @click="downloadImageZip(report.date)"
                          >
                            Download Zip
                          </b-button>
                        </b-field>
                      </div>

                      <div class="column is-12">
                        <div class="columns is-multiline">
                          <div class="column is-3">
                            <label class="label">Assesment Name</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{ report.assessment.title }} - NIT:
                              {{ meta.nit }}
                            </label>
                          </div>
                          <div class="column is-3">
                            <label class="label">Screen Capture</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{ report.screenCaptureList.length }} images
                            </label>
                          </div>
                          <div class="column is-3">
                            <label class="label">Camera's Capture</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{ report.hiddenCameraList.length }} images
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <span class="column is-12">
                        <b-table
                          :data="proctoring ? proctoring : []"
                          :current-page.sync="currentPage"
                          paginated
                          backend-pagination
                          :loading="tableLoading"
                          :per-page="perPage"
                          :total="proctoring.length"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page"
                          backend-sorting
                          :default-sort-direction="defaultSortOrder"
                          :default-sort="[sortField, sortOrder]"
                          @sort="onSort"
                        >
                          <b-table-column
                            field="index"
                            numeric
                            width="20"
                            label="No"
                            v-slot="props"
                          >
                            {{ perPage * (currentPage - 1) + 1 + props.index }}
                          </b-table-column>
                          <b-table-column
                            field="image"
                            label="Image"
                            sortable
                            v-slot="props"
                            width="100"
                          >
                            <img width="100" :src="props.row" />
                          </b-table-column>
                          <b-table-column
                            field="type"
                            label="Type"
                            sortable
                            v-slot="props"
                          >
                            {{ imageType(props.row) }}
                          </b-table-column>
                          <b-table-column
                            field="captured"
                            label="Captured Date"
                            sortable
                            v-slot="props"
                          >
                            {{ pathToTime(props.row) }}
                          </b-table-column>
                          <b-table-column
                            field="action"
                            label="Action"
                            v-slot="props"
                          >
                            <b-button
                              class="button-action"
                              type="icon-button"
                              icon-left="eye"
                              @click="activeModal(props.row)"
                            ></b-button>
                            <a
                              class="button-action"
                              :href="props.row"
                              :download="toFileName(props.row)"
                            >
                              <b-icon
                                style="margin:6px 14px;"
                                icon="download"
                              ></b-icon>
                            </a>
                          </b-table-column>
                          <template slot="empty">
                            <section class="section">
                              <div
                                class="content has-text-grey has-text-centered"
                              >
                                <p>
                                  <b-icon icon="frown" size="is-large"></b-icon>
                                </p>
                                <p>Data not found.</p>
                              </div>
                            </section>
                          </template>
                        </b-table>
                      </span>
                    </div>
                  </b-tab-item>

                  <!-- For proctoring copy/paste data -->
                  <b-tab-item label="Proctoring (Copy/Paste)">
                    <div class="columns is-multiline">
                      <span class="column is-4">
                        <b-field>
                          <b-input
                            v-model="searchProctoring"
                            placeholder="Search..."
                            type="search"
                            icon="search"
                          ></b-input>
                        </b-field>
                      </span>
                      <span class="column is-8">
                        <b-field grouped position="is-right">
                          <b-select v-model="perPageProctoring">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                          </b-select>
                        </b-field>
                      </span>

                      <span class="column is-12">
                        <b-table
                          :data="searchProctoringRecord"
                          :current-page.sync="currentPageProctoring"
                          paginated
                          backend-pagination
                          :loading="tableLoading"
                          :total="searchProctoringRecord.length"
                          :per-page="perPageProctoring"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page"
                          detailed
                          detail-key="id"
                        >
                          <b-table-column
                            field="index"
                            numeric
                            width="20"
                            label="No"
                            v-slot="props"
                          >
                            {{
                              perPageProctoring * (currentPageProctoring - 1) +
                                1 +
                                props.index
                            }}
                          </b-table-column>
                          <b-table-column
                            field="date"
                            label="Date"
                            sortable
                            v-slot="props"
                          >
                            {{ formatDate(props.row.createdAt) }}
                          </b-table-column>
                          <b-table-column
                            field="action"
                            label="Action"
                            sortable
                            width="300"
                            v-slot="props"
                          >
                            {{ props.row.action }}
                          </b-table-column>
                          <b-table-column
                            field="text"
                            label="Text"
                            sortable
                            width="300"
                            v-slot="props"
                          >
                            <template v-if="props.row.text.length > 30">
                              {{ props.row.text.substring(0, 30) }}...
                            </template>
                            <template v-else>
                              {{ props.row.text }}
                            </template>
                          </b-table-column>

                          <!-- For detail data -->
                          <template slot="detail" slot-scope="props">
                            <div class="columns is-multiline">
                              <!-- For Date -->
                              <div class="column is-2">
                                <strong>Date :</strong>
                              </div>
                              <div class="column is-10">
                                {{ formatDate(props.row.createdAt) }}
                              </div>

                              <!-- For Action -->
                              <div class="column is-2">
                                <strong>Action :</strong>
                              </div>
                              <div class="column is-10">
                                {{ props.row.action }}
                              </div>

                              <!-- For Text -->
                              <div class="column is-2">
                                <strong>Text :</strong>
                              </div>
                              <div class="column is-10">
                                {{ props.row.text }}
                              </div>
                            </div>
                          </template>

                          <!-- For empty data -->
                          <template slot="empty">
                            <section class="section">
                              <div
                                class="content has-text-grey has-text-centered"
                              >
                                <p>
                                  <b-icon icon="frown" size="is-large"></b-icon>
                                </p>
                                <p>Data not found.</p>
                              </div>
                            </section>
                          </template>
                        </b-table>
                      </span>
                    </div>
                  </b-tab-item>

                  <!-- For sections -->
                  <b-tab-item label="Sections">
                    <div class="columns is-multiline header-proctoring">
                      <div class="column is-10">
                        <label class="title">Summary</label>
                      </div>
                      <div class="column is-2">
                        <b-field grouped position="is-right">
                          <!-- <b-button
                            type="is-hcc"
                            @click="downloadImageZip(report.date)"
                          >
                            Download Xls
                          </b-button> -->
                        </b-field>
                      </div>

                      <div class="column is-12">
                        <div class="columns is-multiline">
                          <div class="column is-3">
                            <label class="label">Assesment Name</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{ report.assessment.title }} - NIT:
                              {{ meta.nit }}
                            </label>
                          </div>
                          <div class="column is-3">
                            <label class="label">Total Question</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{
                                meta.report.original.data.summary.totalQuestion
                              }}
                              <!-- {{ meta.totalPoints }} / {{ meta.maxPoint }} -->
                            </label>
                          </div>
                          <div class="column is-3">
                            <label class="label">Total Corrects</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{
                                meta.report.original.data.summary.totalCorrects
                              }}
                              <!-- {{ meta.totalPoints }} / {{ meta.maxPoint }} -->
                            </label>
                          </div>
                          <div class="column is-3">
                            <label class="label">Total Wrong</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{ meta.report.original.data.summary.totalWrong }}
                              <!-- {{ meta.totalPoints }} / {{ meta.maxPoint }} -->
                            </label>
                          </div>
                          <div class="column is-3">
                            <label class="label">Total Candidate Points</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{
                                meta.report.original.data.summary
                                  .totalCandidatPoint
                              }}
                              <!-- {{ meta.totalPoints }} / {{ meta.maxPoint }} -->
                            </label>
                          </div>
                          <div class="column is-3">
                            <label class="label">Durations</label>
                          </div>
                          <div class="column is-9">
                            <label class="label">
                              {{ meta.duration }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <span class="column is-12">
                        <b-table
                          :data="sections ? sections : []"
                          paginated
                          backend-pagination
                          :loading="tableLoading"
                          :per-page="perPage"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page"
                          backend-sorting
                          :total="sections.length"
                          :default-sort-direction="defaultSortOrder"
                          :default-sort="[sortField, sortOrder]"
                          @sort="onSort"
                          detailed
                          detail-key="id"
                          @details-open="row => filterTable(row)"
                        >
                          <b-table-column
                            field="index"
                            numeric
                            width="20"
                            label="Question Id"
                            v-slot="props"
                          >
                            {{ props.row.id }}
                          </b-table-column>
                          <!-- <b-table-column
                            field="index"
                            numeric
                            width="20"
                            label="Question No"
                            v-slot="props"
                          >
                            {{ props.row.questionNoReal }}
                          </b-table-column> -->
                          <b-table-column
                            field="index"
                            numeric
                            width="20"
                            label="Group No"
                            v-slot="props"
                          >
                            {{ props.row.group }}
                          </b-table-column>
                          <b-table-column
                            field="question"
                            label="Question"
                            sortable
                            v-slot="props"
                          >
                            <template v-if="props.row.question.length > 30">
                              <span
                                v-html="props.row.question.substring(0, 30)"
                              ></span>
                              ...
                            </template>
                            <template v-else>
                              <span v-html="props.row.question"></span>
                            </template>
                          </b-table-column>
                          <b-table-column
                            field="type"
                            label="Type"
                            sortable
                            v-slot="props"
                          >
                            <span v-html="props.row.type"></span>
                          </b-table-column>
                          <b-table-column
                            field="answer"
                            label="Answer"
                            sortable
                            v-slot="props"
                          >
                            <template
                              v-if="
                                props.row.type == 'ranking' ||
                                  props.row.type == 'matrix'
                              "
                            >
                              See in detail...
                            </template>
                            <template v-else>
                              <template
                                v-if="formatQuestion(props.row) == null"
                              >
                                -
                              </template>
                              <template v-else>
                                <template
                                  v-if="formatQuestion(props.row).length > 30"
                                >
                                  <span
                                    v-html="
                                      formatQuestion(props.row).substring(0, 30)
                                    "
                                  ></span>
                                  ...
                                </template>
                                <template v-else>
                                  <span
                                    v-html="formatQuestion(props.row)"
                                  ></span>
                                </template>
                              </template>
                            </template>
                          </b-table-column>
                          <b-table-column
                            field="created_at"
                            label="Submitted At"
                            sortable
                            v-slot="props"
                          >
                            {{
                              props.row.time == null
                                ? '-'
                                : formatDate(props.row.time)
                            }}
                          </b-table-column>
                          <b-table-column
                            field="point"
                            label="Point"
                            sortable
                            v-slot="props"
                          >
                            {{ props.row.point }}
                          </b-table-column>

                          <!-- For detail data -->
                          <template slot="detail" slot-scope="props">
                            <div
                              class="columns is-multiline"
                              style="width: auto; "
                            >
                              <!-- For Group No -->
                              <div class="column is-2">
                                <strong>Group No :</strong>
                              </div>
                              <div class="column is-10">
                                {{ props.row.group }}
                              </div>

                              <!-- For Question Type -->
                              <div class="column is-2">
                                <strong>Question Type :</strong>
                              </div>
                              <div class="column is-10">
                                {{ props.row.type }}
                              </div>

                              <!-- For Submitted At -->
                              <div class="column is-2">
                                <strong>Submitted At :</strong>
                              </div>
                              <div class="column is-10">
                                {{
                                  props.row.time == null
                                    ? '-'
                                    : formatDate(props.row.time)
                                }}
                              </div>

                              <!-- For Question -->
                              <div class="column is-2">
                                <strong>Question :</strong>
                              </div>
                              <div class="column is-10">
                                <span v-html="props.row.question"></span>
                              </div>

                              <!-- For Answer -->
                              <div class="column is-12">
                                <strong>Answer :</strong>
                              </div>
                              <div class="column is-12">
                                <template v-if="props.row.type == 'ranking'">
                                  <div class="columns">
                                    <!-- <div class="column is-6">
                                      <b>List Question:</b>
                                      <br />
                                      <div
                                        v-for="(question,
                                        indexQuestion) in formatQuestion(
                                          props.row
                                        ).questionList"
                                        :key="indexQuestion"
                                      >
                                        - {{ question.description }}
                                      </div>
                                    </div> -->
                                    <div class="column is-6">
                                      <b>List Answer:</b>
                                      <br />
                                      <div
                                        v-for="(answer,
                                        indexAnswer) in formatQuestion(
                                          props.row
                                        ).answerList"
                                        :key="indexAnswer"
                                      >
                                        {{ answer.indexAnswer }}
                                        - {{ answer.description }}

                                        <span
                                          v-for="(tag, indexTag) in answer.tags"
                                          :key="indexTag"
                                        >
                                          ({{ tag.name }})
                                          <span
                                            v-if="
                                              indexTag < answer.tags.length - 1
                                            "
                                          >
                                            ,
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                                <template
                                  v-else-if="props.row.type == 'matrix'"
                                >
                                  <div class="columns">
                                    <div
                                      class="column is-12 matrix-report"
                                      style="overflow-x: auto;"
                                    >
                                      <span
                                        v-for="(value,
                                        indexMatrix) in newMatrix"
                                        :key="indexMatrix"
                                      >
                                        <span v-if="value.id == props.row.id">
                                          <table class="b-table table">
                                            <tr
                                              v-for="indexRow in value.maxRow"
                                              :key="indexRow"
                                            >
                                              <td
                                                v-for="indexColumn in value.maxColumn"
                                                :key="indexColumn"
                                                style="overflow-wrap: break-word;min-width: 250px;max-width:250px; padding:5px"
                                              >
                                                <span
                                                  v-if="
                                                    value.data[indexRow - 1][
                                                      indexColumn - 1
                                                    ] != null
                                                  "
                                                >
                                                  {{
                                                    value.data[indexRow - 1][
                                                      indexColumn - 1
                                                    ].description
                                                  }}
                                                </span>
                                              </td>
                                            </tr>
                                          </table>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </template>
                                <template v-else>
                                  <br />
                                  <span
                                    v-html="formatQuestion(props.row)"
                                  ></span>
                                </template>
                              </div>
                            </div>
                          </template>

                          <!-- For empty data -->
                          <template slot="empty">
                            <section class="section">
                              <div
                                class="content has-text-grey has-text-centered"
                              >
                                <p>
                                  <b-icon icon="frown" size="is-large"></b-icon>
                                </p>
                                <p>Data not found.</p>
                              </div>
                            </section>
                          </template>
                        </b-table>
                      </span>
                    </div>
                  </b-tab-item>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="isShowImageActive" :width="900">
      <div class="box">
        <img :src="activeImageUrl" width="900" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {
      isFullPage: true,
      activeTab: 0,
      isLoading: true,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortField: 'createdAt',
      sortOrder: 'DESC',
      currentPage: 1,
      perPage: 10,
      searchProctoring: null,
      currentPageProctoring: 1,
      perPageProctoring: 10,
      page: 1,
      search: null,
      surveys: [],
      name: '',
      report: null,
      meta: null,
      sections: null,
      proctoring: [],
      isShowImageActive: false,
      activeImageUrl: null,
      tableLoading: false,
      mydata: null,
      tempMatrixId: [],
      newMatrix: [],
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async downloadExcel(sectionId) {
      var content = []
      content.sectionId = sectionId
      content.reportId = this.$route.params.reportId
      await this.$store.dispatch('downloadExcel', content).then(response => {
        if (response == 400) {
          this.danger('download failed')
        } else {
          this.success('download complete')
        }
      })
    },
    async downloadImageZip(date) {
      var content = {
        date: date,
        nit: this.meta.nit,
        assessmentId: this.meta.assessmentId,
      }
      if (
        this.report.screenCaptureList.length > 0 ||
        this.report.hiddenCameraList.length > 0
      ) {
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element.$el,
        })
        await this.$store
          .dispatch('downloadNewImageZip', content)
          .then(response => {
            if (response == 400) {
              this.danger('download failed')
            } else {
              this.success('download complete')
            }
            loadingComponent.close()
          })
      } else {
        this.danger('photo not found')
      }
    },
    async download(date, image, type) {
      var content = []
      content.date = date
      content.image = image
      content.type = type
      content.nit = this.report.nit
      content.assessmentId = this.report.assessmentId
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      })
      await this.$store.dispatch('downloadImage', content).then(response => {
        if (response == 400) {
          this.danger('download failed')
        } else {
          this.success('download complete')
        }
        loadingComponent.close()
      })
    },
    activeModal(url) {
      this.activeImageUrl = url
      this.isShowImageActive = true
    },
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getNewReport',
        this.$route.params.reportId
      )
      this.report = response.data
      this.proctoring = this.report.hiddenCameraList
      if (this.report.screenCaptureList.length > 0) {
        this.proctoring = this.proctoring.concat(this.report.screenCaptureList)
      }

      this.meta = response.meta
      this.sections = response.data.sections
      this.isLoading = false
    },
    async fetchDataProctoring(perPage, page, sortField, sortOrder) {
      if (this.report != null) {
        var content = []
        content.nit = this.report.nit
        content.assessmentId = this.report.assessmentId
        content.perPage = perPage
        content.page = page
        content.sortField = sortField
        content.sortOrder = sortOrder
        const responseProctoring = await this.$store.dispatch(
          'getProctoring',
          content
        )
        this.proctoring = responseProctoring
      }
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    imageType(imagepath) {
      if (imagepath.includes('hidden-camera')) {
        return "Camera's Capture"
      } else {
        return 'Screen Capture'
      }
    },
    formatDate(date) {
      return moment(date).format('MMMM DD YYYY / h:mm:ss A')
    },
    pathToTime(imagepath) {
      let arr = imagepath.split('/')
      let length = arr.length
      let date = arr[length - 2]
      let time = arr[length - 1]
      let formattedDate = moment(date, 'DDMMYY').format('MMMM DD YYYY')
      let formattedTime = moment(time, 'hhmmss').format('h:mm:ss A')
      return formattedDate + ' / ' + formattedTime
    },
    toFileName(imagepath) {
      let arr = imagepath.split('/')
      let length = arr.length
      let fileName = arr[length - 1]
      return fileName
    },
    formatQuestion(section) {
      if (section.type == 'essay') {
        return section.answer
      }
      if (section.type == 'radio button') {
        let answer = section.answer

        if (answer.url != undefined) {
          return (
            'id : ' +
            answer.id +
            ' , ' +
            answer.answerAlphabet +
            '. ' +
            answer.url
          )
        } else {
          return (
            'id : ' +
            answer.id +
            ' , ' +
            answer.answerAlphabet +
            '. ' +
            answer.optionName
          )
        }
      }
      if (section.type == 'checkbox') {
        let answers = section.answer
        let result = answers.map(item => {
          if (item.url != undefined) {
            return (
              'id : ' +
              item.id +
              ' , ' +
              item.answerAlphabet +
              '. ' +
              ' - ' +
              item.url
            )
          } else {
            return (
              'id : ' +
              item.id +
              ' , ' +
              item.answerAlphabet +
              '. ' +
              ' - ' +
              item.optionName
            )
          }
        })
        return result.join('<br/>')
      } else if (section.type == 'linear scale') {
        return section.answer
      } else if (section.type == 'star rating') {
        return section.answer
      } else if (section.type == 'ranking') {
        let answers = section.answer
        var sortable = []
        for (var x in answers.listAnswer) {
          sortable.push(answers.listAnswer[x])
        }
        sortable.sort(function(a, b) {
          return a.indexAnswer - b.indexAnswer
        })

        let data = {
          questionList: answers.listQuestion,
          answerList: sortable,
        }

        return data
      }
    },
    filterTable(data) {
      if (data.type == 'matrix') {
        if (this.newMatrix.length != 0) {
          if (!this.tempMatrixId.includes(data.id)) {
            this.addMatrix(data)
          }
        } else {
          this.addMatrix(data)
        }
      }
    },
    addMatrix(data) {
      let maxRow = 0
      let maxColumn = 0
      let matrix = data.answer

      maxRow = matrix.sort((a, b) => b.row - a.row)[0].row
      maxColumn = matrix.sort((a, b) => b.column - a.column)[0].column

      let arrMatrix = []
      for (var i = 1; i <= maxRow; i++) {
        let y = new Array()
        for (var j = 1; j <= maxColumn; j++) {
          var found = matrix.find(function(x) {
            return x.row === i && x.column === j
          })
          if (found) {
            y.push(found)
          } else {
            y.push(null)
          }
        }
        arrMatrix.push(y)
      }
      let answerId = data.id
      this.newMatrix.push({
        id: answerId,
        data: arrMatrix,
        maxRow: maxRow,
        maxColumn: maxColumn,
      })
      this.tempMatrixId.push(answerId)
    },
  },
  computed: {
    searchProctoringRecord() {
      if (this.searchProctoring) {
        return this.report.proctoringRecord.filter(proctoring => {
          return this.searchProctoring
            .toLowerCase()
            .split(' ')
            .every(v => proctoring.text.toLowerCase().includes(v))
        })
      } else {
        return this.report.proctoringRecord
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-proctoring {
  //   background-color:
  border-bottom: 1px solid #00a3a3;
}
.tabs ul {
  border-bottom-color: #00a3a3 !important;
}
.button-action {
  border: none !important;
  color: #00a3a3;
}
.word-wrap {
  word-break: break-all;
}
@media (min-width: 1366px) {
  .matrix-report {
    width: auto;
    min-width: 800px;
    max-width: 800px;
  }
}

@media (min-width: 1400px) {
  .matrix-report {
    width: auto;
    min-width: 950px;
    max-width: 950px;
  }
}

@media (min-width: 1600px) {
  .matrix-report {
    width: auto;
    min-width: 1100px;
    max-width: 1100px;
  }
}

@media (min-width: 1800px) {
  .matrix-report {
    width: auto;
    min-width: 1200px;
    max-width: 1200px;
  }
}

@media (min-width: 2000px) {
  .matrix-report {
    width: auto;
    min-width: 1400px;
    max-width: 1400px;
  }
}

@media (min-width: 2200px) {
  .matrix-report {
    width: auto;
    min-width: 1950px;
    max-width: 1950px;
  }
}
</style>
